//HEIGHT

import { heightFormat, max_height, min_height } from "../utils"

function getHeight() {
    const height = []
    for (let i = min_height; i <= max_height; i++) {
        height.push({
            value: i,
            name: heightFormat(i),
        })
    }
    return height
}

export const height = getHeight()
export const gender = ['Men', 'Women']
export const language = ['Hindi', 'English', 'Haryanvi', 'Punjabi', 'Himachali', 'Kashmiri', 'Sindhi', 'Urdu', 'Marathi', 'Gujarati', 'Kutchi', 'Konkani', 'Tamil', 'Telgu', 'Kannada', 'Malyalam', 'Tullu', 'Bengali', 'Oriya', 'Assamase', 'Nepali']
export const profile_managed_by = ['Self', 'Parent', 'Sibling', 'Relative/Friend', 'Other']
export const complexion = ['Fair', 'Wheatish', 'Dark']
export const weight_category = ['Slim', 'Athletic', 'Heavy']
export const manglik = ['Manglik', 'Non Manglik', 'Angshik Manglik']
export const diet = ['Vegetarian', 'Non Vegetarian', 'Jain', 'Eggetarian']
export const kundli_milan = ['Yes', 'No']
export const disability = ['Yes', 'No']
export const martial_status = ['Never Married', 'Divorce', 'Widow/Widower']
export const disease = ['None', 'Thalassemia', 'HIV+', 'Diabetes', 'Cancer', 'Other']
export const kundali_milan = ['Yes', 'No']
export const drink = ['Never', 'Socially', 'Regularly', 'Planning to quit']
export const family_status = ['Rich/Affluent', 'Upper Middle Class', 'Middle Class', 'General Class']
export const family_type = ['Joint Family', 'Nuclear Family', 'Living Alone']
export const family_values = ['Orthodox', 'Conservative', 'Moderate', 'Liberal']
export const employed_in = [
    'Private Secor',
    'Government/Pubic Sector',
    'Civil Services',
    'Defence',
    'Business/ Self Employed',
    'Not Working',
]
export const occupation = [
    { label: 'Accounting Professional', category: 'Banking & Finance' },
    { label: 'Share Broker', category: 'Banking & Finance' },
    { label: 'Auditor', category: 'Banking & Finance' },
    { label: 'Banking Professional', category: 'Banking & Finance' },
    { label: 'Charted Accountant', category: 'Banking & Finance' },
    { label: 'Finance Professional', category: 'Banking & Finance' },
    { label: 'Actor/Model', category: 'Advertising, Media & Entertainment' },
    { label: 'Advertising Professional', category: 'Advertising, Media & Entertainment' },
    { label: 'Film/ Entertainment Professional', category: 'Advertising, Media & Entertainment' },
    { label: 'Journalist', category: 'Advertising, Media & Entertainment' },
    { label: 'Media Professional', category: 'Advertising, Media & Entertainment' },
    { label: 'PR Professional', category: 'Advertising, Media & Entertainment' },
    { label: 'Admin Professional', category: 'Administration' },
    { label: 'Clerk', category: 'Administration' },
    { label: 'Operator/Technician', category: 'Administration' },
    { label: 'Secretary/Front Office', category: 'Administration' },
    { label: 'Agriculture Professional', category: 'Agricultural' },
    { label: 'Farming', category: 'Agricultural' },
    { label: 'Airline Professional', category: 'Airline & Aviation' },
    { label: 'Flight Attendant', category: 'Airline & Aviation' },
    { label: 'Pilot', category: 'Airline & Aviation' },
    { label: 'Analyst', category: 'Corporate Management Professionals' },
    { label: 'Consultant', category: 'Corporate Management Professionals' },
    { label: 'Corporate Communication', category: 'Corporate Management Professionals' },
    { label: 'Corporate Planning', category: 'Corporate Management Professionals' },
    { label: 'HR Professional', category: 'Corporate Management Professionals' },
    { label: 'Marketing Professional', category: 'Corporate Management Professionals' },
    { label: 'Operations Management', category: 'Corporate Management Professionals' },
    { label: 'Product Manager', category: 'Corporate Management Professionals' },
    { label: 'Program Manager', category: 'Corporate Management Professionals' },
    { label: 'Project Manager - IT', category: 'Corporate Management Professionals' },
    { label: 'Project Manager - Non IT', category: 'Corporate Management Professionals' },
    { label: 'Sales Professional', category: 'Corporate Management Professionals' },
    { label: 'Sr. Manager/ Manager', category: 'Corporate Management Professionals' },
    { label: 'Subject Matter Expert', category: 'Corporate Management Professionals' },
    { label: 'Animator', category: 'Software & IT' },
    { label: 'Cyber/Network Security', category: 'Software & IT' },
    { label: 'Project Lead - IT', category: 'Software & IT' },
    { label: 'Quality Assurance Engineer - IT', category: 'Software & IT' },
    { label: 'Software Professional', category: 'Software & IT' },
    { label: 'UI/UX Designer', category: 'Software & IT' },
    { label: 'Web/Graphic Designer', category: 'Software & IT' },
    { label: 'Architect', category: 'Architecture' },
    { label: 'BPO/ITes Professional', category: 'BPO & Customer Service' },
    { label: 'Customer Service', category: 'BPO & Customer Service' },
    { label: 'CxO/ Chairman/ President/ Director', category: 'Top Management' },
    { label: 'VP/ AVP/ GM/ DGM', category: 'Top Management' },
    { label: 'Dentist', category: 'Doctor' },
    { label: 'Doctor', category: 'Doctor' },
    { label: 'Surgeon', category: 'Doctor' },
    { label: 'Education Professional', category: 'Education & Training' },
    { label: 'Educational Institution Owner', category: 'Education & Training' },
    { label: 'Librarian', category: 'Education & Training' },
    { label: 'Professor/Lecturer', category: 'Education & Training' },
    { label: 'Research Assistant', category: 'Education & Training' },
    { label: 'Teacher', category: 'Education & Training' },
    { label: 'Electronics Engineering', category: 'Engineering' },
    { label: 'Hardware/Telecom Engineer', category: 'Engineering' },
    { label: 'Non - IT Engineer', category: 'Engineering' },
    { label: 'Quality Assurance Engineer', category: 'Engineering' },
    { label: 'Hotels/Hospitality Professional', category: 'Hospitality' },
    { label: 'Lawyer & Legal Professional', category: 'Legal' },
    { label: 'Mariner', category: 'Merchant Navy' },
    { label: 'Merchant Naval Officer', category: 'Merchant Navy' },
    { label: 'Medical/ Healthcare Professional', category: 'Other Medical & Healthcare' },
    { label: 'Nurse', category: 'Other Medical & Healthcare' },
    { label: 'Paramedic', category: 'Other Medical & Healthcare' },
    { label: 'Pharmacist', category: 'Other Medical & Healthcare' },
    { label: 'Physiotherapist', category: 'Other Medical & Healthcare' },
    { label: 'Psychologist', category: 'Other Medical & Healthcare' },
    { label: 'Veterinary Doctor', category: 'Other Medical & Healthcare' },
    { label: 'Research Professional', category: 'Science & Research' },
    { label: 'Science Professional', category: 'Science & Research' },
    { label: 'Scientist', category: 'Science & Research' },
    { label: 'Agent', category: 'Others' },
    { label: 'Artist', category: 'Others' },
    { label: 'Beautician', category: 'Others' },
    { label: 'Broker', category: 'Others' },
    { label: 'Business', category: 'Others' },
    { label: 'Fashion Designer', category: 'Others' },
    { label: 'Entrepreneur', category: 'Others' },
    { label: 'Interior Designer', category: 'Others' },
    { label: 'Others', category: 'Others' },
    { label: 'Security Professional', category: 'Others' },
    { label: 'Singer', category: 'Others' },
    { label: 'Social Service/ NGO/ Volunteer', category: 'Others' },
    { label: 'Sportsperson', category: 'Others' },
    { label: 'Travel Professional', category: 'Others' },
    { label: 'Writer', category: 'Others' },
]

export const education = [
    { label: 'A.M.E.', category: 'Engineering/Technology/Design' },
    { label: 'B.Arch', category: 'Engineering/Technology/Design' },
    { label: 'B.Des', category: 'Engineering/Technology/Design' },
    { label: 'B.E/B.Tech', category: 'Engineering/Technology/Design' },
    { label: 'B.FAD', category: 'Engineering/Technology/Design' },
    { label: 'B.HTech', category: 'Engineering/Technology/Design' },
    { label: 'B.Pharma', category: 'Engineering/Technology/Design' },
    { label: 'B.Tech LL.B.', category: 'Engineering/Technology/Design' },
    { label: 'BID', category: 'Engineering/Technology/Design' },
    { label: 'CISE', category: 'Engineering/Technology/Design' },
    { label: 'ITIL', category: 'Engineering/Technology/Design' },
    { label: 'M.Arch', category: 'Engineering/Technology/Design' },
    { label: 'M.Des', category: 'Engineering/Technology/Design' },
    { label: 'M.E/M.Tech', category: 'Engineering/Technology/Design' },
    { label: 'M.FTech', category: 'Engineering/Technology/Design' },
    { label: 'M.Pharma', category: 'Engineering/Technology/Design' },
    { label: 'M.S. (Engineering)', category: 'Engineering/Technology/Design' },
    { label: 'MIB', category: 'Engineering/Technology/Design' },
    { label: 'MID', category: 'Engineering/Technology/Design' },
    { label: 'MPD', category: 'Engineering/Technology/Design' },
    { label: 'BCA', category: 'Computers' },
    { label: 'DCA', category: 'Computers' },
    { label: 'MCA', category: 'Computers' },
    { label: 'MCM', category: 'Computers' },
    { label: 'PGDCA', category: 'Computers' },
    { label: 'B.Com', category: 'Finance/Commerce/Economics' },
    { label: 'B.Com (Hons)', category: 'Finance/Commerce/Economics' },
    { label: 'BBE', category: 'Finance/Commerce/Economics' },
    { label: 'BBI', category: 'Finance/Commerce/Economics' },
    { label: 'CA', category: 'Finance/Commerce/Economics' },
    { label: 'CFA', category: 'Finance/Commerce/Economics' },
    { label: 'CFP', category: 'Finance/Commerce/Economics' },
    { label: 'CIA', category: 'Finance/Commerce/Economics' },
    { label: 'CPA', category: 'Finance/Commerce/Economics' },
    { label: 'CS', category: 'Finance/Commerce/Economics' },
    { label: 'ICWA', category: 'Finance/Commerce/Economics' },
    { label: 'M.Com', category: 'Finance/Commerce/Economics' },
    { label: 'MBE', category: 'Finance/Commerce/Economics' },
    { label: 'MBF', category: 'Finance/Commerce/Economics' },
    { label: 'MFC', category: 'Finance/Commerce/Economics' },
    { label: 'MFM', category: 'Finance/Commerce/Economics' },
    { label: 'B.H.A.', category: 'Management' },
    { label: 'BAM', category: 'Management' },
    { label: 'BBA', category: 'Management' },
    { label: 'BBM', category: 'Management' },
    { label: 'BFM', category: 'Management' },
    { label: 'BFT', category: 'Management' },
    { label: 'BHM', category: 'Management' },
    { label: 'BHMCT', category: 'Management' },
    { label: 'BHMTT', category: 'Management' },
    { label: 'BMS', category: 'Management' },
    { label: 'CWM', category: 'Management' },
    { label: 'Executive MBA/PGDM', category: 'Management' },
    { label: 'FPM', category: 'Management' },
    { label: 'MAM', category: 'Management' },
    { label: 'MBA/PGDM', category: 'Management' },
    { label: 'MBM', category: 'Management' },
    { label: 'MHA', category: 'Management' },
    { label: 'MHRM', category: 'Management' },
    { label: 'MMM', category: 'Management' },
    { label: 'MMS', category: 'Management' },
    { label: 'MTA', category: 'Management' },
    { label: 'MTM', category: 'Management' },
    { label: 'ANM', category: 'Medicine/Health' },
    { label: 'B.O.Th', category: 'Medicine/Health' },
    { label: 'B.P.E.S.', category: 'Medicine/Health' },
    { label: 'B.P.Ed', category: 'Medicine/Health' },
    { label: 'BAMS', category: 'Medicine/Health' },
    { label: 'BCVT', category: 'Medicine/Health' },
    { label: 'BDS', category: 'Medicine/Health' },
    { label: 'BHMS', category: 'Medicine/Health' },
    { label: 'BMLT', category: 'Medicine/Health' },
    { label: 'BMRIT', category: 'Medicine/Health' },
    { label: 'BMRT', category: 'Medicine/Health' },
    { label: 'BNYS', category: 'Medicine/Health' },
    { label: 'BOT', category: 'Medicine/Health' },
    { label: 'BPH', category: 'Medicine/Health' },
    { label: 'BPMT', category: 'Medicine/Health' },
    { label: 'BPO', category: 'Medicine/Health' },
    { label: 'BRDIT', category: 'Medicine/Health' },
    { label: 'BUMS', category: 'Medicine/Health' },
    { label: 'BVSc.', category: 'Medicine/Health' },
    { label: 'D.P.Ed', category: 'Medicine/Health' },
    { label: 'D.Pharma', category: 'Medicine/Health' },
    { label: 'DM', category: 'Medicine/Health' },
    { label: 'DMLT', category: 'Medicine/Health' },
    { label: 'GNM', category: 'Medicine/Health' },
    { label: 'M.D.', category: 'Medicine/Health' },
    { label: 'M.Optom.', category: 'Medicine/Health' },
    { label: 'M.S. (Medicine)', category: 'Medicine/Health' },
    { label: 'MBBS', category: 'Medicine/Health' },
    { label: 'MCh', category: 'Medicine/Health' },
    { label: 'MDS', category: 'Medicine/Health' },
    { label: 'MOT', category: 'Medicine/Health' },
    { label: 'MPT', category: 'Medicine/Health' },
    { label: 'MS', category: 'Medicine/Health' },
    { label: 'MVSc.', category: 'Medicine/Health' },
    { label: 'BL/LLB', category: 'Law' },
    { label: 'ML/LLM', category: 'Law' },
    { label: 'BA', category: 'Arts/Science' },
    { label: 'B.Ed', category: 'Arts/Science' },
    { label: 'B.Sc.', category: 'Arts/Science' },
    { label: 'BFA', category: 'Arts/Science' },
    { label: 'MA', category: 'Arts/Science' },
    { label: 'M.Ed', category: 'Arts/Science' },
    { label: 'M.Sc.', category: 'Arts/Science' },
    { label: 'MFA', category: 'Arts/Science' },
    { label: 'M.Phill', category: 'Doctorate' },
    { label: 'Ph.D', category: 'Doctorate' },
    { label: 'High School', category: 'Non-Graduate' },
    { label: 'Vocational School', category: 'Non-Graduate' },
    { label: 'Diploma', category: 'Non-Graduate' },
    { label: 'Civil Services', category: 'Civil Services' },
    { label: 'Other Bachelors', category: 'Others' },
    { label: 'Other Masters', category: 'Others' },
    { label: 'Others', category: 'Others' },
]

export const visibilityMenuItems = ['Everybody', 'Only to Interest Sent/ Matches', 'Only to Matches', 'Only Registered Users', 'Nobody']
